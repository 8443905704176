<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        v-if="showMenu"
        :tabBarStyle="{
                    margin: '0',
                  }"
        v-model="active"
      >
        <a-tab-pane v-if="$getPermission($route.path + '/boss')" key="all" tab="全院当年确认项目"></a-tab-pane>
        <a-tab-pane key="dept" tab="部门全部项目"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px" v-if="showMenu">
          <a-button
            v-if="$getPermission($route.path + '/plan/month')"
            @click="$router.push($route.path + '/plan/month')"
            type="default"
          >填报月计划</a-button>
          <a-button
            v-if="$getPermission($route.path + '/plan/year')"
            @click="$router.push($route.path + '/plan/year')"
            type="default"
          >填报年计划</a-button>
          <a-badge :count="count" v-if="$getPermission($route.path + '/plan')">
            <a-button @click="$router.push($route.path + '/plan')" type="primary">计划管理</a-button>
          </a-badge>
        </a-space>
      </template>
    </Pane>

    <Dept v-show="active === 'dept'" />
    <Boss v-show="active === 'all'" />
  </div>
</template>

<script>
import Dept from "./components/dept.vue";
import Boss from "./components/boss.vue";

import request from "@/api/request";

function fetchData() {
  return request({
    url: "/project-service/projectPlan/count",
    method: "post"
  });
}

export default {
  name: "progress",

  components: {
    Dept,
    Boss
  },

  data() {
    return {
      showMenu: true,
      active: "",
      count: 0
    };
  },

  activated() {
    const { from } = this.$route.query;
    if(from == 'dataBoard'){
      this.showMenu = false
    }
    if (this.$getPermission(this.$route.path + "/boss")) {
      this.active = "all";
    } else {
      this.active = "dept";
    }

    if (this.$getPermission(this.$route.path + "/plan")) {
      this.getData();
    }
  },

  methods: {
    getData() {
      fetchData().then(res => {
        console.log("count", res);
        if (typeof res === "number") {
          this.count = res;
        }
      });
    }
  }
};
</script>