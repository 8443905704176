<template>
  <span>
    <span @click="visible = true">
      <a-icon type="right" />
    </span>

    <a-modal :visible="visible" title="当月已确认" :footer="null" @cancel="visible = false">
      <a-select style="width: 100%" v-model="dept">
        <a-select-option
          v-for="item in deptList"
          :key="item.name"
          :value="item.name"
        >{{ item.name }}</a-select-option>
      </a-select>

      <Padding />

      <a-table bordered :data-source="filteredList" :loading="loading" :pagination="false">
        <a-table-column title="部门" data-index="deptName" />
        <a-table-column title="确认月份" data-index="month" align="center" />
        <a-table-column title="确认金额(万)" align="right">
          <template slot-scope="text">
            <span
              style="color: #1890ff"
            >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="search(text)">查询</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import request from "@/api/request";

function fetchList() {
  return request({
    url: "/project-service/project/dept/month/approvedAmount/list"
  });
}

function fetchMonth() {
  return request({
    url: "/project-service/project/dept/currentMonth/approvedAmount/all"
  });
}

export default {
  props: {
    type: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      visible: false,
      loading: false,
      list: [],

      dept: undefined
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.loading = true;

        let task;
        if (this.type === "dept") {
          task = fetchList;
        } else if (this.type === "month") {
          task = fetchMonth;
        }

        if (!task) return;

        task()
          .then(res => {
            this.list = Array.isArray(res) ? res : [];
            this.dept = this.department ? this.department.deptUniqueName : "";
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["department"]),

    deptList() {
      return this.list
        .map(item => item.deptName)
        .filter((item, index, self) => self.indexOf(item) === index)
        .map(name => {
          return {
            name
          };
        });
    },

    filteredList() {
      return this.dept
        ? this.list.filter(item => item.deptName === this.dept)
        : [];
    }
  },

  methods: {
    search(text) {
      this.visible = false;
      this.$emit("search", [
        {
          type: "deptId",
          value: text.deptId
        },
        {
          type: "date",
          value: text.month
        }
      ]);
    }
  }
};
</script>