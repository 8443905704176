<template>
  <div>
    <a-modal
      title="拆分子项目"
      :visible="visible"
      @cancel="cancel"
      @ok="ok"
      :maskClosable="false"
      width="1000px"
    >
      <a-form
        :colon="false"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 22 }"
      >
        <a-form-item label="设计编号">
          {{ project.designCode }}
        </a-form-item>
        <a-form-item label="项目名称">
          {{ project.name }}
        </a-form-item>
        <a-form-item label="项目金额">
          <Money :money="project.projectAmount" />
          万元
        </a-form-item>

        <a-form-item label="子项列表">
          <a-space>
            <div style="width: 180px">设计编号</div>
            <div style="width: 440px">子项目名称</div>
            <div style="width: 120px">子项目金额</div>
          </a-space>

          <div v-for="(item, index) in list" :key="index">
            <a-space>
              <a-input
                placeholder="设计编号"
                v-model="item.designCode"
                style="width: 180px"
              />
              <a-input
                placeholder="子项目名称"
                style="width: 440px"
                v-model="item.name"
              />
              <a-input-number
                placeholder="子项目金额"
                v-model="item.projectAmount"
                :min="0"
                style="width: 120px"
              />
              <a-button
                v-if="index === list.length - 1"
                size="small"
                type="primary"
                shape="circle"
                icon="plus"
                @click="add"
              ></a-button>
              <a-button
                v-if="index !== 0"
                size="small"
                type="danger"
                shape="circle"
                icon="minus"
                @click="remove(index)"
              ></a-button>
            </a-space>

            <div
              class="left"
              style="margin-bottom: 24px"
              v-if="item.majorList && item.majorList.length > 0"
            >
              <div
                v-for="(element, index) in item.majorList"
                :key="index"
                style="margin-right: 8px"
              >
                <a-tooltip>
                  <template slot="title">
                    {{ element.companyName }}
                  </template>

                  <a-button type="primary">
                    <DataDictFinder
                      dictType="majorTypes"
                      :dictValue="element.major"
                    />
                  </a-button>
                </a-tooltip>

                <a-input-number
                  v-model="element.percent"
                  style="width: 80px"
                  :formatter="$ratioFormatter"
                />
              </div>
            </div>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { splitSon } from "@/api/project";
import { mapGetters } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 项目
    project: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      list: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    messageList() {
      return this.findDataDict("message");
    },

    // 如果设计类-非必要分包中有技术3，则过滤出来，作为模板
    majorTemplateList() {
      if (Array.isArray(this.project.subPackages)) {
        const filterList = this.project.subPackages.filter(
          (item) => item.type === "JS3"
        );
        return filterList.map((item) => {
          return {
            ...item,
            percent: (100 / filterList.length).toFixed(2),
          };
        });
      } else {
        return [];
      }
    },
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        this.list = [
          {
            designCode: this.project.designCode,
            name: this.project.name,
            projectAmount: (this.project.projectAmount / 10000).toFixed(6),
            majorList: JSON.parse(JSON.stringify(this.majorTemplateList)),
          },
        ];
      }
    },
  },

  methods: {
    add() {
      const lastElement = this.list[this.list.length - 1];
      const { designCode, name, projectAmount } = lastElement;
      if (!designCode || !name || !projectAmount) {
        this.$message.error("请完善子项目信息！");
        return;
      }

      let newDesignCode = ``;

      this.list.push({
        designCode: newDesignCode,
        name,
        projectAmount: 0,
        majorList: JSON.parse(JSON.stringify(this.majorTemplateList)),
      });
    },

    remove(index) {
      this.list.splice(index, 1);
    },

    cancel() {
      const that = this;
      this.$confirm({
        title: "关闭后系统自动清除数据，是否继续？",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          that.$emit("setVisible", false);
        },
      });
    },

    ok() {
      let money = 0;
      let message = "";

      let majorRatio = 0;

      for (let i = 0; i < this.list.length; i++) {
        const element = this.list[i];
        money += element.projectAmount * 10000;

        if (!element.projectAmount) {
          message = "子项目金额不能为0！";
          break;
        }

        if (Array.isArray(element.majorList)) {
          element.majorList.forEach((item) => {
            majorRatio += Number(item.percent);
          });
        }
      }

      if (message) {
        this.$message.error(message);
        return;
      }
      console.log(this.project.projectAmount - money);
      if (Math.abs(Number(this.project.projectAmount - money)) > 0.000001) {
        this.$message.error("子项目金额之和需等于父项目！");
        return;
      }

      console.log(
        "majorratio",
        majorRatio,
        100 * this.majorTemplateList.length
      );
      if (majorRatio !== 100 * this.majorTemplateList.length) {
        this.$message.error("各个专业分包成本比例需达到100%！");
        return;
      }

      const that = this;
      this.$confirm({
        title: this.messageList.find((item) => item.value === "splitProject")
          .name,
        onOk() {
          that.save();
        },
      });
    },

    save() {
      splitSon({
        id: this.project.id,
        projectAmount: this.project.projectAmount,
        sonList: [
          ...this.list.map((item) => {
            return {
              pid: this.project.pid,
              ...item,
              projectAmount: item.projectAmount * 10000,
              subList: item.majorList,
            };
          }),
        ]
      }).then(() => {
        this.$emit("setVisible", false);
      });
    },
  },
};
</script>