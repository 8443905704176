<template>
  <span>
    <span @click="visible = true">
      <a-icon type="right" />
    </span>

    <a-modal :visible="visible" title="当年考核进度收入" :footer="null" @cancel="visible = false">
      <div class="center">
        <a-radio-group v-model="selectedTpye" button-style="solid">
          <a-radio-button value="dept">按部门</a-radio-button>
          <a-radio-button value="month">按月份</a-radio-button>
        </a-radio-group>
      </div>
      <Padding />
      <Padding />

      <div v-if="selectedTpye === 'dept'">
        <a-table bordered :data-source="list" :loading="loading" :pagination="false">
          <a-table-column title="部门" data-index="deptName" />
          <a-table-column title="当年考核进度收入(万)" align="right">
            <template slot-scope="text">
              <span
                style="color: #1890ff"
              >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center">
            <template slot-scope="text">
              <a href="#" @click.prevent="searchDept(text.deptFullId)">查询</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <div v-else>
        <a-table bordered :data-source="list" :loading="loading" :pagination="false">
          <a-table-column title="月份" data-index="month" align="center" />
          <a-table-column title="当年考核进度收入(万)" align="right">
            <template slot-scope="text">
              <span
                style="color: #1890ff"
              >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center">
            <template slot-scope="text">
              <a href="#" @click.prevent="searchDate(text.month)">查询</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </a-modal>
  </span>
</template>

<script>
import request from "@/api/request";

function fetchYear(params) {
  return request({
    url: '/project-service/project/dept/year/allocateAmount',
    params
  });
}

export default {
    props: {
        range: {
            type: String,
            default: '0', // 全院
        }
    },
  data() {
    return {
      visible: false,
      selectedTpye: "dept",
      loading: false,
      list: []
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.getData();
      }
    },

    selectedTpye() {
      this.getData();
    }
  },

  methods: {
    getData() {
      this.loading = true;

      fetchYear({
        type: this.selectedTpye,
        range: this.range,
      })
        .then(res => {
          this.list = Array.isArray(res) ? res : [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    searchDept(deptFullId) {
      this.visible = false;
      this.$emit("search", [{
        type: "staffProductionUnitFullId",
        value: deptFullId
      }, {
          type: "year",
      }]);
    },
    searchDate(date) {
      this.visible = false;
      this.$emit("search", [{
        type: "year",
        value: date
      }]);
    }
  }
};
</script>