<template>
  <span>
    <span @click="visible = true">
      <a-icon type="right" />
    </span>

    <a-modal :visible="visible" title="当月计划" :footer="null" @cancel="visible = false" width='860px'>
      <a-table bordered :data-source="list" :loading="loading" :pagination="false">
        <a-table-column title="部门" data-index="deptName" />
        <!-- <a-table-column title="计划月份" data-index="month" align="center" /> -->

          <a-table-column-group align='center'>
             <div style="text-align: center" slot="title">当月计划确认(万元)</div>

        <a-table-column :title="`第${item}次`" align="center" v-for="(item,index) in length" :key="Math.random() + '' + item">
          <template slot-scope="text">
                            <span
              style="color: #1890ff"
              
            >
            {{(Array.isArray(text.amounList) && typeof text.amounList[index] === 'number') ? text.amounList[index].toLocaleString() : '--'}}</span>
          </template>
        </a-table-column>
          </a-table-column-group>



      <a-table-column title="当月已确认(万元)" align="right">
          <template slot-scope="text">
            <span
              style="color: #1890ff"
            >{{typeof text.approvedAmount === 'number' ? text.approvedAmount.toLocaleString() : 0}}</span>
          </template>
        </a-table-column>
        

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="seeDetail(text)">详情</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>

<script>
import request from "@/api/request";

function fetchList() {
  return request({
    url: "/project-service/projectPlan/currentMonth/dept",
    method: "post"
  });
}

export default {
  data() {
    return {
      visible: false,
      loading: false,
      list: []
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.loading = true;
        fetchList()
          .then(res => {
            this.list = Array.isArray(res) ? res : [];
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },

  computed: {
    length() {
      let length = 0;
       this.list.forEach(item => {
        if (Array.isArray( item.amounList)) {
          if (item.amounList.length > length) {
            length = item.amounList.length;
          }
        }
      });
      return length;
    }
  },
  methods: {
    seeDetail(text) {
      if (text.planId) {
        this.$router.push(this.$route.path + "/plan/detail?id=" + text.planId);
      } else {
        this.$message.error('没有计划')
      }
    }
  }
};
</script>