var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-icon',{staticClass:"setting",attrs:{"type":"setting"},on:{"click":function($event){_vm.visible = true}}}),_c('a-modal',{attrs:{"title":"分院数据管理","visible":_vm.visible,"footer":null,"width":"600px"},on:{"cancel":_vm.cancel}},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"label",staticStyle:{"padding-right":"8px"}},[_vm._v("年份")]),_c('a-select',{staticStyle:{"width":"160px"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}},_vm._l(([
          {
              name: '2024',
              value: 2024,
          },{
              name: '2023',
              value: 2023,
          }, {
                        name: '2022',
              value: 2022,
          }, {
                        name: '2021',
              value: 2021,
          }, {
                        name: '2020',
              value: 2020,
          }]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":false}},[_c('a-table-column',{attrs:{"title":"月份","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(_vm._s(text.month + "月"))]}}])}),_c('a-table-column',{attrs:{"title":"当月计划确认(万元)","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-input-number',{attrs:{"size":"small"},model:{value:(text.planAmount),callback:function ($$v) {_vm.$set(text, "planAmount", $$v)},expression:"text.planAmount"}})]}}])}),_c('a-table-column',{attrs:{"title":"当月已确认(万元)","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-input-number',{attrs:{"size":"small"},model:{value:(text.approvedAmount),callback:function ($$v) {_vm.$set(text, "approvedAmount", $$v)},expression:"text.approvedAmount"}})]}}])})],1),_c('div',{staticClass:"right",staticStyle:{"padding-top":"16px"}},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.save}},[_vm._v("保存")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }